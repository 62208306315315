/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
export const NO_LOCATION_DATA = 'NO_LOCATION_DATA';
export const oconusLocations = [
    { name: 'ALASKA' },
    { name: 'HAWAII' },
    { name: 'PUERTO_RICO' },
    { name: 'US_VIRGIN_ISLANDS' },
    { name: 'UNITED_KINGDOM' },
    { name: 'NETHERLANDS' },
    { name: 'ITALY' },
    { name: 'GERMANY' },
    { name: 'SOUTH_KOREA' },
    { name: 'JAPAN' },
    { name: 'GUAM' },
    { name: 'POLAND' },
];
export const oconusText = [
    'alaska-text',
    'hawaii-text',
    'puertorico-text',
    'usvirginislands-text',
    'unitedkingdom-text',
    'netherlands-text',
    'italy-text',
    'germany-text',
    'southkorea-text',
    'japan-text',
    'guam-text',
    'poland-text',
];
export const conusText = ['ri-text', 'md-text', 'dc-text'];

export const oconusAutoSuggestions = [...oconusLocations, { name: 'MY COUNTRY IS NOT LISTED' }];
export const getShapeId = (id) => {
    const shapes = {};
    shapes['alaska-text'] = 'ALASKA';
    shapes['hawaii-text'] = 'HAWAII';
    shapes['puertorico-text'] = 'PUERTO_RICO';
    shapes['usvirginislands-text'] = 'US_VIRGIN_ISLANDS';
    shapes['unitedkingdom-text'] = 'UNITED_KINGDOM';
    shapes['netherlands-text'] = 'NETHERLANDS';
    shapes['italy-text'] = 'ITALY';
    shapes['germany-text'] = 'GERMANY';
    shapes['southkorea-text'] = 'SOUTH_KOREA';
    shapes['japan-text'] = 'JAPAN';
    shapes['guam-text'] = 'GUAM';
    shapes['poland-text'] = 'POLAND';
    shapes['ri-text'] = 'ri';
    shapes['md-text'] = 'md';
    shapes['dc-text'] = 'dc';

    return shapes[id];
};
export const getTextId = (id) => {
    const text = {};
    text.alaska = 'alaska-text';
    text.hawaii = 'hawaii-text';
    text.puerto_rico = 'puertorico-text';
    text.us_virgin_islands = 'usvirginislands-text';
    text.united_kingdom = 'unitedkingdom-text';
    text.netherlands = 'netherlands-text';
    text.italy = 'italy-text';
    text.germany = 'germany-text';
    text.southkorea = 'southkorea-text';
    text.japan = 'japan-text';
    text.guam = 'guam-text';
    text.ri = 'ri-text';
    text.md = 'md-text';
    text.dc = 'dc-text';
    text.poland = 'poland-text';

    return text[id];
};
export const getFullName = (abbreviation) => {
    const fullName = {};
    fullName.ARNG = 'Army National Guard';
    fullName.ANG = 'Air National Guard';
    fullName.NG = 'National Guard';
    fullName.NOSC = 'Navy Operational Support Center';
    fullName.SOCOM = 'United States Special Operations Command';
    fullName.USA = 'United States Army';
    fullName.USAF = 'United States Air Force';
    fullName.USAFR = 'United States Air Force Reserve';
    fullName.USAR = 'United States Army Reserve';
    fullName.USAREC = 'United States Army Recruiting Command';
    fullName.USMC = 'United States Marine Corps';
    fullName.USMCR = 'US Marine Corps Reserves';
    fullName.USNR = 'United States Navy Reserve';

    return fullName[abbreviation] ? fullName[abbreviation] : 'TBD';
};

export const noLocations = ['vt'];
